import video from "../../assets/images/video1.mp4";

const HeroBackground = () => {
  return (
    <video className="body-overlay opacity-01" loop autoPlay muted src={video}>
      <source src={video} type="video/mp4"></source>
    </video>
  );
};

export default HeroBackground;
