import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Resume = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section id="resume" className="resume-area page-section scroll-to-page">
      <div className="custom-container">
        <div className="resume-content content-width">
          <div className="section-header">
            <h4 className="subtitle scroll-animation" data-aos="fade-up">
              <i className="las la-briefcase"></i> Resume
            </h4>
            <h1 className="scroll-animation" data-aos="fade-up">
              Education & <span>Experience</span>
            </h1>
          </div>

          <div className="resume-timeline">
            <div className="item scroll-animation" data-aos="fade-right">
              <span className="date">Jan 2024 - Present</span>
              <h2>Software Engineer</h2>
              <p>Codejust</p>
              <h2>Frontend & Mobile Development Instructor</h2>
              <p>Siliconmade Academy</p>
            </div>
            <div className="item scroll-animation" data-aos="fade-left">
              <span className="date">Dec 2022 - Jan 2024</span>
              <h2>Software Development Team Lead</h2>
              <p>Siliconmade Academy</p>
            </div>
            <div className="item scroll-animation" data-aos="fade-right">
              <span className="date">Feb 2021 - Dec 2022</span>
              <h2>Software Developer</h2>
              <p>Robo AI Software</p>
              <h2>Business Analyst, UX Designer</h2>
              <p>Robo AI Software</p>
            </div>
            <div className="item scroll-animation" data-aos="fade-left">
              <span className="date">Jun 2019 - Sep 2022</span>
              <h2>Software Developer - Freelance</h2>
              <p>Siliconmade Academy</p>
              <h2>Junior Software Developer</h2>
              <p>Siliconmade Academy</p>
              <h2>Software Developer Intern</h2>
              <p>Siliconmade Academy</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resume;
