import {
  Hero,
  About,
  Contact,
  Portfolio,
  Resume,
  Services,
  Skills,
} from "./index";

const Main = () => {
  return (
    <main className="deniz-main">
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <Hero />
          <About />
          <Resume />
          <Services />
          <Skills />
          <Portfolio />
          <Contact />
        </div>
      </div>
    </main>
  );
};
export default Main;
