import React from "react";
import me from "../../assets/images/me.jpg";

const LeftSideBar = () => {
  return (
    <div className="left-sidebar">
      <div className="sidebar-header d-flex align-items-center justify-content-between">
        <div className="name">S. Deniz Ekinci</div>
        <span className="designation">Software Developer</span>
      </div>
      <img className="me" src={me} alt="Me" />
      <h2 className="email">hello@denizekinci.dev</h2>
      <h2 className="address">Base in Edirne, Turkey</h2>
      <ul className="social-profile d-flex align-items-center flex-wrap justify-content-center">
        <li>
          <a
            href="https://twitter.com/DenizEkinci35"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="lab la-twitter"></i>
          </a>
        </li>
        <li>
          <a
            href="https://linkedin.com/in/sinandenizekinci"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="lab la-linkedin"></i>
          </a>
        </li>
        <li>
          <a
            href="https://instagram.com/sinandenizekinci"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="lab la-instagram"></i>
          </a>
        </li>
        <li>
          <a
            href="https://github.com/denizekinci"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="lab la-github"></i>
          </a>
        </li>
      </ul>
      <a href="#contact" className="theme-btn">
        <i className="las la-envelope"></i> Contact
      </a>
    </div>
  );
};

export default LeftSideBar;
