import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section id="about" className="about-area page-section scroll-to-page">
      <div className="custom-container">
        <div className="about-content content-width">
          <div className="section-header">
            <h4 className="subtitle scroll-animation" data-aos="fade-up">
              <i className="lar la-user"></i> About
            </h4>
            <h1 className="scroll-animation" data-aos="fade-up">
              Every software solution begins
              <br />
              with an even <span>better story</span>
            </h1>
          </div>
          <p className="scroll-animation" data-aos="fade-up">
            Embarking on my journey as a software developer over 4 years ago,
            I've spearheaded digital transformations for businesses, contributed
            to startup ecosystems, and engineered CRM projects with finesse. My
            experience spans across frontend, backend, and mobile development,
            where I've honed my skills through diverse projects and
            collaborations. With a blend of seasoned expertise and perpetual
            curiosity, I'm dedicated to tackling each coding challenge with
            precision and innovation, continuously refining my craft with each
            line of code.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
