import LeftSideBar from "../components/layout/left-sidebar.component";
import HeroBackground from "../components/layout/hero-background.component";
import TopMenu from "../components/layout/top-menu.component";
import Menu from "../components/layout/menu.component";
import Main from "../components/content/main.component";

const Home = () => {
  return (
    <div>
      <HeroBackground />
      <LeftSideBar />
      <TopMenu />
      <Menu />
      <Main />
    </div>
  );
};

export default Home;
