import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import roundText from "../../assets/images/round-text.png";

const Hero = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section id="home" className="hero-section page-section scroll-to-page">
      <div className="custom-container">
        <div className="hero-content content-width">
          <div className="section-header">
            <h4
              className="subtitle scroll-animation"
              data-animation="fade_from_bottom"
              data-aos="fade-up"
            >
              <i className="las la-home"></i> Introduce
            </h4>
            <h1 className="scroll-animation" data-aos="fade-up">
              Say Hi from <span>Deniz</span>, Software Developer
            </h1>
          </div>
          <p className="scroll-animation" data-aos="fade-up">
            I specialize in developing scalable and optimized web and mobile
            applications tailored to your needs using modern technologies. With
            a focus on leveraging the latest tools and frameworks, I strive to
            deliver efficient solutions that seamlessly meet the demands of
            today's digital landscape. Let's collaborate to bring your ideas to
            life and create impactful digital experiences!
          </p>
          <a
            href="#portfolio"
            className="go-to-project-btn scroll-to scroll-animation"
            data-aos="fade-up"
          >
            <img src={roundText} alt="Rounded Text" />
            <i className="las la-arrow-down"></i>
          </a>
          <div className="facts d-flex">
            <div className="left scroll-animation" data-aos="fade-right">
              <h1>4+</h1>
              <p>
                Years of <br />
                Experience
              </p>
            </div>
            <div className="right scroll-animation" data-aos="fade-left">
              <h1>20+</h1>
              <p>
                projects completed on <br />4 countries
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
