import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Lightbox from "lightbox2";
import "lightbox2/dist/css/lightbox.min.css";

import portfolio1 from "../../assets/images/portfolio1.jpg";
import portfolio2 from "../../assets/images/portfolio2.jpg";
import portfolio3 from "../../assets/images/portfolio3.jpg";
import portfolio4 from "../../assets/images/portfolio4.jpg";
import portfolio5 from "../../assets/images/portfolio5.jpg";

// import 'lightbox2/dist/js/lightbox.js';

const Portfolio = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    Lightbox.option({
      resizeDuration: 200,
      fadeDuration: 600,
      imageFadeDuration: 600,
      wrapAround: true,
    });
  }, []);
  return (
    <section
      id="portfolio"
      className="portfolio-area page-section scroll-to-page"
    >
      <div className="custom-container">
        <div className="portfolio-content content-width">
          <div className="section-header">
            <h4 className="subtitle scroll-animation" data-aos="fade-up">
              <i className="las la-grip-vertical"></i> portfolio
            </h4>
            <h1 className="scroll-animation">
              Featured <span>Projects</span>
            </h1>
          </div>

          <div className="row portfolio-items">
            <div className="col-md-12 scroll-animation" data-aos="fade-up">
              <div className="portfolio-item portfolio-full">
                <div className="portfolio-item-inner">
                  <a href={portfolio1} data-lightbox="example-1">
                    <img src={portfolio1} alt="Portfolio" />
                  </a>

                  <ul className="portfolio-categories">
                    <li>
                      <a
                        href="https://www.laravel.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Laravel
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.vuejs.org"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Vue.js
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.ionic.io"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Ionic
                      </a>
                    </li>
                  </ul>
                </div>
                <h2>
                  <a
                    href="https://robosay.com.tr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Robosay - House Management System
                  </a>
                </h2>
              </div>
            </div>

            <div className="col-md-6 scroll-animation" data-aos="fade-right">
              <div className="portfolio-item portfolio-half">
                <div className="portfolio-item-inner">
                  <a href={portfolio2} data-lightbox="example-1">
                    <img src={portfolio2} alt="Portfolio" />
                  </a>

                  <img src={portfolio2} alt="Portfolio" />

                  <ul className="portfolio-categories">
                    <li>
                      <a
                        href="https://www.wordpress.org"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        WordPress
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.php.org"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        PHP
                      </a>
                    </li>
                  </ul>
                </div>
                <h2>
                  <a
                    href="https://www.bctr.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blockchain Turkey Platform
                  </a>
                </h2>
              </div>
            </div>

            <div className="col-md-6 scroll-animation" data-aos="fade-left">
              <div className="portfolio-item portfolio-half">
                <div className="portfolio-item-inner">
                  <a href={portfolio3} data-lightbox="example-1">
                    <img src={portfolio3} alt="Portfolio" />
                  </a>

                  <ul className="portfolio-categories">
                    <li>
                      <a
                        href="https://www.wordpress.org"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        WordPress
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.php.org"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        PHP
                      </a>
                    </li>
                  </ul>
                </div>
                <h2>
                  <a
                    href="https://ai.org.tr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    AI.ORG.TR - Turkey's AI Portal
                  </a>
                </h2>
              </div>
            </div>

            <div className="col-md-12 scroll-animation" data-aos="fade-up">
              <div className="portfolio-item portfolio-half">
                <div className="portfolio-item-inner">
                  <a href={portfolio4} data-lightbox="example-1">
                    <img src={portfolio4} alt="portfolio" />
                  </a>

                  <ul className="portfolio-categories">
                    <li>
                      <a
                        href="https://www.vuejs.org"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Vue.js
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.laravel.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Laravel
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.reactnative.dev"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        React Native
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://dotnet.microsoft.com/en-us/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        .NET Core
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://nodejs.org/en/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Node.js
                      </a>
                    </li>
                  </ul>
                </div>
                <h2>
                  <a
                    href="https://www.siliconmadeacademy.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    STARS - CRM for Siliconmade Academy
                  </a>
                </h2>
              </div>
            </div>

            <div className="col-md-12 scroll-animation" data-aos="fade-up">
              <div className="portfolio-item portfolio-half">
                <div className="portfolio-item-inner">
                  <a href={portfolio5} data-lightbox="example-1">
                    <img src={portfolio5} alt="portfolio" />
                  </a>

                  <ul className="portfolio-categories">
                    <li>
                      <a
                        href="https://www.wordpress.org"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        WordPress
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.php.org"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        PHP
                      </a>
                    </li>
                  </ul>
                </div>
                <h2>
                  <a href="https://www.lewis.com">
                    DTG - Digital Technology Developers
                  </a>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
