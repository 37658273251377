import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Services = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section
      id="services"
      className="services-area page-section scroll-to-page"
    >
      <div className="custom-container">
        <div className="services-content content-width">
          <div className="section-header">
            <h4 className="subtitle scroll-animation" data-aos="fade-up">
              <i className="las la-stream"></i> Services
            </h4>
            <h1 className="scroll-animation" data-aos="fade-up">
              My <span>Specializations</span>
            </h1>
          </div>

          <div className="services-items">
            <div className="service-item scroll-animation" data-aos="fade-up">
              <i className="las la-bezier-curve"></i>
              <h2>Websites</h2>
              <p>
                I develop websites using WordPress or modern web frameworks.
              </p>
              <span className="projects">24 Projects</span>
            </div>
            <div className="service-item scroll-animation" data-aos="fade-up">
              <i className="las la-code"></i>
              <h2>Frontend, Backend Development</h2>
              <p>
                I create frontend and backend applications using Vue.js, React,
                and Laravel.
              </p>
              <span className="projects">8 Projects</span>
            </div>
            <div className="service-item scroll-animation" data-aos="fade-up">
              <i className="las la-mobile"></i>
              <h2>Mobile Apps</h2>
              <p>
                Utilizing React Native, I develop mobile applications for both
                iOS and Android platforms.
              </p>
              <span className="projects">3 Projects</span>
            </div>
            <div className="service-item scroll-animation" data-aos="fade-up">
              <i className="las la-rocket"></i>
              <h2>SEO/Digital Transformation</h2>
              <p>Increase the traffic for your website with SEO optimized</p>
              <span className="projects">6 Projects</span>
            </div>
            <div className="service-item scroll-animation" data-aos="fade-up">
              <i className="las la-shopping-bag"></i>
              <h2>E-Commerce Infrastructure</h2>
              <p>Coming soon!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
