import "./assets/css/bootstrap.min.css";
import "./assets/css/App.scss";
import "./assets/css/responsive.scss";
import Home from "./pages/home.component";

const App = () => {
  return <Home />;
};

export default App;
