import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import figmaImage from "../../assets/images/figma.png";
import awsImage from "../../assets/images/aws-white.png";
import vueImage from "../../assets/images/vue.png";
import reactImage from "../../assets/images/react.png";
import wordPressImage from "../../assets/images/wordpress.png";
import laravelImage from "../../assets/images/laravel.png";
import reactNativeImage from "../../assets/images/react-native.png";
import dockerImage from "../../assets/images/docker.png";

const Skills = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section id="skills" className="skills-area page-section scroll-to-page">
      <div className="custom-container">
        <div className="skills-content content-width">
          <div className="section-header">
            <h4 className="subtitle scroll-animation" data-aos="fade-up">
              <i className="las la-shapes"></i> my skills
            </h4>
            <h1 className="scroll-animation" data-aos="fade-up">
              My <span>Advantages</span>
            </h1>
          </div>
          <div className="row skills text-center">
            <div className="col-md-3 scroll-animation" data-aos="fade-left">
              <div className="skill">
                <div className="skill-inner">
                  <img src={reactImage} alt="React" />
                  <h1 className="percent">React</h1>
                </div>
              </div>
            </div>
            <div className="col-md-3 scroll-animation" data-aos="fade-right">
              <div className="skill">
                <div className="skill-inner">
                  <img src={vueImage} alt="Figma" />
                  <h1 className="">Vue.js</h1>
                </div>
              </div>
            </div>
            <div className="col-md-3 scroll-animation" data-aos="fade-up">
              <div className="skill">
                <div className="skill-inner">
                  <img src={reactNativeImage} alt="Framer" />
                  <h1 className="percent">React N.</h1>
                </div>
              </div>
            </div>
            <div className="col-md-3 scroll-animation" data-aos="fade-up">
              <div className="skill">
                <div className="skill-inner">
                  <img src={laravelImage} alt="Laravel/PHP" />
                  <h1 className="percent">Laravel</h1>
                </div>
              </div>
            </div>
            <div className="col-md-3 scroll-animation" data-aos="fade-right">
              <div className="skill">
                <div className="skill-inner">
                  <img src={wordPressImage} alt="WordPress" />
                  <h1 className="percent">WordPress</h1>
                </div>
              </div>
            </div>
            <div className="col-md-3 scroll-animation" data-aos="fade-right">
              <div className="skill">
                <div className="skill-inner">
                  <img src={figmaImage} alt="WordPress" />
                  <h1 className="percent">Figma</h1>
                </div>
              </div>
            </div>
            <div className="col-md-3 scroll-animation" data-aos="fade-right">
              <div className="skill">
                <div className="skill-inner">
                  <img src={awsImage} alt="WordPress" />
                  <h1 className="percent">AWS</h1>
                </div>
              </div>
            </div>
            <div className="col-md-3 scroll-animation" data-aos="fade-right">
              <div className="skill">
                <div className="skill-inner">
                  <img src={dockerImage} alt="WordPress" />
                  <h1 className="percent">Docker</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
