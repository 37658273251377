import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Swal from "sweetalert2";

const Contact = () => {
  useEffect(() => {
    AOS.init({ duration: 1300 });
  }, []);

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://mailer.denizekinci.dev/api/send-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: formData.fullName,
            email: formData.email,
            phone: formData.phoneNumber,
            subject: formData.subject,
            message: formData.message,
            mailto: "hello@denizekinci.dev",
          }),
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");

      Swal.fire({
        title: "Success!",
        text: "Your message has been sent successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "There was a problem sending your message.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("There was a problem with your fetch operation:", error);
    }
  };

  return (
    <section id="contact" className="contact-area page-section scroll-content">
      <div className="custom-container">
        <div className="contact-content content-width">
          <div className="section-header">
            <h4 className="subtitle scroll-animation" data-aos="fade-up">
              <i className="las la-envelope"></i> contact
            </h4>
            <h1 className="scroll-animation" data-aos="fade-up">
              Let's Work <span>Together!</span>
            </h1>
          </div>
          <h3 className="scroll-animation" data-aos="fade-up">
            hello@denizekinci.dev
          </h3>
          <p id="required-msg">* Marked fields are required to fill.</p>

          <form
            className="contact-form scroll-animation"
            data-aos="fade-up"
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-md-6">
                <div className="input-group">
                  <label htmlFor="full-name">
                    full Name <sup>*</sup>
                  </label>
                  <input
                    type="text"
                    name="fullName"
                    id="full-name"
                    placeholder="Your Full Name"
                    value={formData.fullName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-group">
                  <label htmlFor="email">
                    Email <sup>*</sup>
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Your email adress"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-group">
                  <label htmlFor="phone-number">
                    phone <span>(optional)</span>
                  </label>
                  <input
                    type="text"
                    name="phoneNumber"
                    id="phone-number"
                    placeholder="Your phone number"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-group">
                  <label htmlFor="subject">
                    subject <sup>*</sup>
                  </label>
                  <select
                    name="subject"
                    id="subject"
                    value={formData.subject}
                    onChange={handleChange}
                  >
                    <option value="">Select a subject</option>
                    <option value="subject1">General Inquiry</option>
                    <option value="subject2">
                      Interested in Freelance Collaboration
                    </option>
                    <option value="subject3">
                      Interested in Full-Time Employment
                    </option>
                    <option value="subject4">Feedback/Suggestions</option>
                    <option value="subject5">Request for Information</option>
                    <option value="subject6">Technical Support Request</option>
                    <option value="subject7">
                      Business Partnership Inquiry
                    </option>
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <div className="input-group">
                  <label htmlFor="message">message</label>
                  <textarea
                    name="message"
                    id="message"
                    placeholder="Write your message here ..."
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12">
                <div className="input-group submit-btn-wrap">
                  <button
                    className="theme-btn"
                    name="submit"
                    type="submit"
                    id="submit-form"
                  >
                    send message
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
